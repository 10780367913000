$cardwhitePadding:15px;
$paymentContainerVericalPadding:8px;
#payment-option-container,.subheader-container{
  padding: 10px $paymentContainerVericalPadding;
  #payment-options-label,>.subheader-label{
    font-size: 1.1rem !important;
    border-bottom: 3px solid #eaeaea;
    padding-bottom: 6px;
    width: 100%;
    h6{
      margin-right:$cardwhitePadding -$paymentContainerVericalPadding ;
      margin-left:$cardwhitePadding -$paymentContainerVericalPadding ;
    }

  }
  #payment-options-field{
    padding: 15px;
  }
}
