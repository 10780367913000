
/* File Create To Add Custom Style To I need Help
 Widget Plugin From Freshwork.com Here Spereately Than
 The Global Styles */


//Adjust The widget Button Position
iframe#launcher-frame{
  bottom: -11px !important;
}

//The widget Form Position
div#freshworks-frame-wrapper{
  bottom:40px !important;
}
