//
// home.scss
//

@mixin home-common {
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}
.bg-home {
  height: 100vh;
  @include home-common();
}
.bg-half-260 {
  padding: 260px 0;
  @include home-common();
}
.bg-half-170 {
  padding: 100px 0;
  @include home-common();
}
.bg-half {
  padding: 184px 0 90px;
  @include home-common();
}

.bg-auth-home {
  padding: 142px 0;
  @include home-common();
}

//Gradient Home
.bg-circle-gradiant {
  background: radial-gradient(
    circle at 50% 50%,
    rgba($color-primary, 0.1) 0%,
    rgba($color-primary, 0.1) 33.333%,
    rgba($color-primary, 0.3) 33.333%,
    rgba($color-primary, 0.3) 66.666%,
    rgba($color-primary, 0.5) 66.666%,
    rgba($color-primary, 0.5) 99.999%
  );
}

//It Solutions
.it-home {
  animation: animate-left-to-right 60s linear infinite;
}

@keyframes animate-left-to-right {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}

//Saas
.home-dashboard {
  img {
    position: relative;
    top: 60px;
    z-index: 1;
  }

  //Index-onepage.html
  @media (min-width: 768px) {
    &.onepage-hero {
      overflow: hidden;
    }
  }
}

.classic-saas-image .bg-saas-shape {
  &:after {
    bottom: 3rem;
    //left: 0;
    width: 60rem;
    height: 55rem;
    border-radius: 20rem;
    @media (max-width: 767px) {
      left: 20rem;
      width: 30rem;
      height: 30rem;
      border-radius: 10rem;
    }
  }
}

.classic-saas-image .bg-saas-shape,
.freelance-hero .bg-shape {
  img {
    @media screen and (min-width: 768px) and (max-width: 1500px) {
      max-width: 800px;
    }
    @media (max-width: 768px) {
      max-width: 550px;
    }

    @media (max-width: 767px) {
      max-width: 100%;
      height: auto;
    }
  }
}

.classic-saas-image .bg-saas-shape,
.freelance-hero .bg-shape {
  &:after {
    opacity: 0.9;
    transform: rotate(130deg);

    @media (max-width: 767px) {
      bottom: -5rem !important;
      height: 30rem;
    }
  }
}

// Freelancer Home
.freelance-hero .bg-shape {
  &:after {
    bottom: 0rem;
    left: 7rem;
    width: 100rem;
    height: 70rem;
    border-radius: 6rem;
    @media (max-width: 768px) {
      bottom: -10rem;
    }
    @media (max-width: 767px) {
      left: 15rem;
      width: 40rem;
      height: 30rem;
    }
  }
}

.freelance-hero .bg-shape {
  &:after {
    box-shadow: 0 0 40px rgba($color-primary, 0.5);
    background-color: lighten($color-primary, 25%);
  }
}

//Studio
.studio-home {
  padding: 7%;
}

//Agency
.agency-wrapper {
  &:before {
    content: "";
    position: absolute;
    bottom: 22rem;
    right: 54rem;
    width: 90rem;
    height: 35rem;
    border-radius: 17rem;
    transform: rotate(45deg);
    background-color: #f8f9fc;
    z-index: -1;

    @media (max-width: 768px) {
      right: 12rem;
    }

    @media (max-width: 767px) {
      right: 0;
    }
  }
}

//Marketing
.bg-marketing {
  padding: 76px 0 120px;
  @include home-common();
}

//Coworking Landing / Hotal / Shop
.home-slider {
  .carousel-control-next,
  .carousel-control-prev {
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 30px;
    border: 1px solid #ced4da;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 30%;
  }
}

.bg-animation-left {
  &:after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 70%;
    height: 100%;
    background: rgba(#fff, 0.85);
    clip-path: polygon(0 0, 90% 0, 70% 100%, 0% 100%);

    @media (max-width: 767px) {
      width: 100%;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
  }
  &.dark-left {
    &:after {
      background: rgba(#000, 0.6);
    }
  }
  &.crypto-home {
    &:after {
      background: $color-primary;
      width: 80%;
      clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
    }
  }
  &.task-management-home {
    &:after {
      // background: url("../images/task/bg.png") $color-primary;
      width: 100%;
      clip-path: circle(65% at 40% 25%);
    }
  }
}

//page-invoice.html
.bg-invoice {
  padding: 100px 0;
  @include home-common();
}

//Application
.classic-app-image {
  .bg-app-shape {
    &:after {
      bottom: 3rem;
      left: -8rem;
      width: 55rem;
      height: 35rem;
      border-radius: 20rem;
      transform: rotate(120deg);
      opacity: 0.9;
    }
  }
  .app-images {
    position: absolute;
    bottom: 60px;
    right: -20px;
  }
}

.classic-app-image .bg-app-shape,
.classic-saas-image .bg-saas-shape,
.freelance-hero .bg-shape {
  &:after {
    content: " ";
    position: absolute;
    z-index: -1;
  }
}
.classic-app-image .bg-app-shape,
.classic-saas-image .bg-saas-shape {
  &:after {
    -webkit-box-shadow: 0 0 40px rgba(55, 172, 223, 0.5);
    box-shadow: 0 0 40px rgba(55, 172, 223, 0.5);
    background-color: #37acdf;
  }
}

//Landing one
.shape-before {
  .shape-img {
    position: absolute;
    top: -50px;
    left: -35px;
    z-index: -1;
    height: auto;
    overflow: hidden;
  }
}

//corporate business
.swiper-slider-hero {
  .swiper-container,
  .swiper-slide .slide-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .swiper-container {
    .swiper-button-prev,
    .swiper-button-next {
      background: transparent;
      width: 35px;
      height: 35px;
      line-height: 35px;
      margin-top: -30px;
      &:before {
        font-family: "Material Design Icons";
        font-weight: 900;
        color: #fff;
      }
      &:hover {
        background: $color-primary;
        border-color: $color-primary !important;
      }
    }
    .swiper-button-prev {
      left: 10px;
      &:before {
        content: "\f0141";
      }
    }
    .swiper-button-next {
      right: 10px;
      &:before {
        content: "\f0142";
      }
    }
  }
  .swiper-pagination-bullet {
    color: #f8f9fa;
    background: transparent;
  }
  .swiper-pagination-bullet-active {
    color: #fff;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 45px;
  }
  .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 13px;
  }
}

// Responsive
@media (min-width: 769px) {
  //studio
  .bg-studio {
    &:after {
      content: "";
      position: absolute;
      right: 0px;
      top: 0px;
      width: 50%;
      height: 100%;
      background: #fff none repeat scroll 0 0;
      z-index: 2;
    }
  }
}

@media (max-width: 768px) {
  .bg-half {
    padding: 169px 0 50px;
  }
}

@media (max-width: 767px) {
  .bg-home,
  .bg-half-170,
  .bg-half-260,
  .bg-marketing,
  .swiper-slider-hero .swiper-container .swiper-slide {
    padding: 150px 0;
    height: auto;
  }
}
