.border-container {
  padding: 30px 2%;
  .header {
    margin-bottom: 15px;
  }
  .subHeader{
    margin:15px 0px;
  }
  .title{

    text-align: center!important;
  }
  .subHeader .title{
    color:#45aae2;
  }
}
.panel-header{
  cursor: pointer;
  i{
    font-size: 25px;
    float: right;
  }
  &.ar{
    i{
      float:left;
    }
  }
}
