@import "./iNeedHelpWidgetStyle.scss";
@import "./activationAndContractRequestPageStyle.scss";
$color-primary: rgb(39, 170, 225);
$color-secondary: rgb(27, 117, 188);
$color-thirdy: rgb(128, 130, 133);

@import "../../../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel.scss";
@import "../../../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import "../css/menu";
@import "../css/home";
@import "./prdouctSubHearStyles.scss";

@font-face {
  font-family: "Cairo";
  src: url("../fonts/Cairo/static/Cairo-Bold.ttf");
  font-weight: bold;
  font-display: swap;
}
@font-face {
  font-family: "Cairo";
  src: url("../fonts/Cairo/static/Cairo-Regular.ttf");
  font-weight: normal;
  font-display: swap;
}

html {
  top: unset !important;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1350px !important;
  }
}

.row > * {
  position: relative;
}

.bg-primary {
  background-color: $color-primary !important;
}

.bg-soft-primary {
  background-color: rgba(47, 85, 212, 0.1) !important;
  border: 1px solid rgba(47, 85, 212, 0.1) !important;
  color: $color-primary !important;
}

.text-primary {
  color: $color-primary !important;
}

a {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

a.text-primary:hover,
a.text-primary:focus {
  color: $color-secondary !important;
}

.bg-secondary {
  background-color: #5a6d90 !important;
}

.bg-soft-secondary {
  background-color: rgba(90, 109, 144, 0.1) !important;
  border: 1px solid rgba(90, 109, 144, 0.1) !important;
  color: #5a6d90 !important;
}

.text-secondary {
  color: #5a6d90 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #506180 !important;
}

.bg-success {
  background-color: #2eca8b !important;
}

.bg-soft-success {
  background-color: rgba(46, 202, 139, 0.1) !important;
  border: 1px solid rgba(46, 202, 139, 0.1) !important;
  color: #2eca8b !important;
}

.text-success {
  color: #2eca8b !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #29b57d !important;
}

.bg-warning {
  background-color: #f17425 !important;
}

.bg-soft-warning {
  background-color: rgba(241, 116, 37, 0.1) !important;
  border: 1px solid rgba(241, 116, 37, 0.1) !important;
  color: #f17425 !important;
}

.text-warning {
  color: #f17425 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ed650f !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.bg-soft-info {
  background-color: rgba(23, 162, 184, 0.1) !important;
  border: 1px solid rgba(23, 162, 184, 0.1) !important;
  color: #17a2b8 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #148ea1 !important;
}

.bg-danger {
  background-color: #e43f52 !important;
}

.bg-soft-danger {
  background-color: rgba(228, 63, 82, 0.1) !important;
  border: 1px solid rgba(228, 63, 82, 0.1) !important;
  color: #e43f52 !important;
}

.text-danger {
  color: #e43f52 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #e1293e !important;
}

.bg-dark {
  background-color: #3c4858 !important;
}

.bg-soft-dark {
  background-color: rgba(60, 72, 88, 0.1) !important;
  border: 1px solid rgba(60, 72, 88, 0.1) !important;
  color: #3c4858 !important;
}

.text-dark {
  color: #3c4858 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #323c49 !important;
}

.bg-muted {
  background-color: #8492a6 !important;
}

.text-justify {
  text-align: justify;
}

.bg-soft-muted {
  background-color: rgba(132, 146, 166, 0.1) !important;
  border: 1px solid rgba(132, 146, 166, 0.1) !important;
  color: #8492a6 !important;
}

.text-muted {
  color: #8492a6 !important;
}
.bg-gradient {
  background-image: linear-gradient(
    90deg,
    rgba(27, 117, 188, 1) 21%,
    rgba(39, 170, 225, 1) 93%
  );
}
a.text-muted:hover,
a.text-muted:focus {
  color: #75859b !important;
}

.bg-light {
  background-color: #f8f9fc !important;
}

.bg-soft-light {
  background-color: rgba(248, 249, 252, 0.1) !important;
  border: 1px solid rgba(248, 249, 252, 0.1) !important;
  color: #f8f9fc !important;
}

.text-light {
  color: #f8f9fc !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #e6eaf4 !important;
}

.bg-blue {
  background-color: $color-primary !important;
}

.bg-soft-blue {
  background-color: rgba(47, 85, 212, 0.1) !important;
  border: 1px solid rgba(47, 85, 212, 0.1) !important;
  color: $color-primary !important;
}

.text-blue {
  color: $color-primary !important;
}

a.text-blue:hover,
a.text-blue:focus {
  color: $color-secondary !important;
}

.bg-footer {
  background-color: #202942 !important;
}

.bg-soft-footer {
  background-color: rgba(32, 41, 66, 0.1) !important;
  border: 1px solid rgba(32, 41, 66, 0.1) !important;
  color: #202942 !important;
}

.text-footer {
  color: #202942 !important;
}

a.text-footer:hover,
a.text-footer:focus {
  color: #181e30 !important;
}

.d-flex .flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.bg-black {
  background: #161c2d !important;
}

video:focus {
  outline: none;
}

.list-inline-item:not(:last-child) {
  margin-right: 0px;
  margin-bottom: 5px;
}

.rounded {
  border-radius: 6px !important;
}

.rounded-top {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.rounded-left {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.rounded-bottom {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.rounded-right {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.rounded-md {
  border-radius: 10px !important;
}

.rounded-lg {
  border-radius: 30px !important;
}

.border {
  border: 1px solid #e9ecef;
}

.border-top {
  border-top: 1px solid #e9ecef;
}

.border-bottom {
  border-bottom: 1px solid #e9ecef;
}

.border-left {
  border-left: 1px solid #e9ecef;
}

.border-right {
  border-right: 1px solid #e9ecef;
}

.border-primary {
  border-color: $color-primary !important;
}

.border-info {
  border: 1px solid #17a2b8 !important;
}

.color-info {
  color: #17a2b8 !important;
}

.small,
small {
  font-size: 90%;
}

.card .card-body {
  padding: 1.5rem;
}

.btn-primary {
  background-color: $color-primary !important;
  border: 1px solid $color-primary !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus {
  background-color: $color-secondary !important;
  border-color: $color-secondary !important;
  color: #ffffff !important;
}

.btn-soft-primary {
  background-color: rgba(47, 85, 212, 0.1) !important;
  border: 1px solid rgba(47, 85, 212, 0.1) !important;
  color: $color-primary !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-soft-primary:hover,
.btn-soft-primary:focus,
.btn-soft-primary:active,
.btn-soft-primary.active,
.btn-soft-primary.focus {
  background-color: $color-primary !important;
  border-color: $color-primary !important;
  color: #ffffff !important;
}

.btn-outline-primary {
  border: 1px solid $color-primary;
  color: $color-primary;
  background-color: transparent;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: $color-primary;
  border-color: $color-primary;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-secondary {
  background-color: #5a6d90 !important;
  border: 1px solid #5a6d90 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active,
.btn-secondary.focus {
  background-color: #465571 !important;
  border-color: #465571 !important;
  color: #ffffff !important;
}

.btn-soft-secondary {
  background-color: rgba(90, 109, 144, 0.1) !important;
  border: 1px solid rgba(90, 109, 144, 0.1) !important;
  color: #5a6d90 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
}

.btn-soft-secondary:hover,
.btn-soft-secondary:focus,
.btn-soft-secondary:active,
.btn-soft-secondary.active,
.btn-soft-secondary.focus {
  background-color: #5a6d90 !important;
  border-color: #5a6d90 !important;
  color: #ffffff !important;
}

.btn-outline-secondary {
  border: 1px solid #5a6d90;
  color: #5a6d90;
  background-color: transparent;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: #5a6d90;
  border-color: #5a6d90;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
}

.btn-success {
  background-color: #15d828 !important;
  border: 1px solid #15d828 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
  box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.focus {
  background-color: #01a511 !important;
  border-color: #01a511 !important;
  color: #ffffff !important;
}

.btn-soft-success {
  background-color: rgba(46, 202, 139, 0.1) !important;
  border: 1px solid rgba(46, 202, 139, 0.1) !important;
  color: #2eca8b !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
  box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
}

.btn-soft-success:hover,
.btn-soft-success:focus,
.btn-soft-success:active,
.btn-soft-success.active,
.btn-soft-success.focus {
  background-color: #2eca8b !important;
  border-color: #2eca8b !important;
  color: #ffffff !important;
}

.btn-outline-success {
  border: 1px solid #2eca8b;
  color: #2eca8b;
  background-color: transparent;
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.focus,
.btn-outline-success:not(:disabled):not(.disabled):active {
  background-color: #2eca8b;
  border-color: #2eca8b;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
  box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
}

.btn-warning {
  background-color: #f17425 !important;
  border: 1px solid #f17425 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
  box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.focus {
  background-color: #d55b0e !important;
  border-color: #d55b0e !important;
  color: #ffffff !important;
}

.btn-soft-warning {
  background-color: rgba(241, 116, 37, 0.1) !important;
  border: 1px solid rgba(241, 116, 37, 0.1) !important;
  color: #f17425 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
  box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
}

.btn-soft-warning:hover,
.btn-soft-warning:focus,
.btn-soft-warning:active,
.btn-soft-warning.active,
.btn-soft-warning.focus {
  background-color: #f17425 !important;
  border-color: #f17425 !important;
  color: #ffffff !important;
}

.btn-outline-warning {
  border: 1px solid #f17425;
  color: #f17425;
  background-color: transparent;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.focus,
.btn-outline-warning:not(:disabled):not(.disabled):active {
  background-color: #f17425;
  border-color: #f17425;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
  box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
}

.btn-info {
  background-color: #17a2b8 !important;
  border: 1px solid #17a2b8 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus {
  background-color: #117a8b !important;
  border-color: #117a8b !important;
  color: #ffffff !important;
}

.btn-soft-info {
  background-color: rgba(23, 162, 184, 0.1) !important;
  border: 1px solid rgba(23, 162, 184, 0.1) !important;
  color: #17a2b8 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
}

.btn-soft-info:hover,
.btn-soft-info:focus,
.btn-soft-info:active,
.btn-soft-info.active,
.btn-soft-info.focus {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  color: #ffffff !important;
}

.btn-outline-info {
  border: 1px solid #17a2b8;
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.focus,
.btn-outline-info:not(:disabled):not(.disabled):active {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
}

.btn-danger {
  background-color: #e43f52 !important;
  border: 1px solid #e43f52 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
  box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.focus {
  background-color: #d21e32 !important;
  border-color: #d21e32 !important;
  color: #ffffff !important;
}

.btn-soft-danger {
  background-color: rgba(228, 63, 82, 0.1) !important;
  border: 1px solid rgba(228, 63, 82, 0.1) !important;
  color: #e43f52 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
  box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
}

.btn-soft-danger:hover,
.btn-soft-danger:focus,
.btn-soft-danger:active,
.btn-soft-danger.active,
.btn-soft-danger.focus {
  background-color: #e43f52 !important;
  border-color: #e43f52 !important;
  color: #ffffff !important;
}

.btn-outline-danger {
  border: 1px solid #e43f52;
  color: #e43f52;
  background-color: transparent;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.focus,
.btn-outline-danger:not(:disabled):not(.disabled):active {
  background-color: #e43f52;
  border-color: #e43f52;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
  box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
}

.btn-dark {
  background-color: #3c4858 !important;
  border: 1px solid #3c4858 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.btn-dark.focus {
  background-color: #272f3a !important;
  border-color: #272f3a !important;
  color: #ffffff !important;
}

.btn-soft-dark {
  background-color: rgba(60, 72, 88, 0.1) !important;
  border: 1px solid rgba(60, 72, 88, 0.1) !important;
  color: #3c4858 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
}

.btn-soft-dark:hover,
.btn-soft-dark:focus,
.btn-soft-dark:active,
.btn-soft-dark.active,
.btn-soft-dark.focus {
  background-color: #3c4858 !important;
  border-color: #3c4858 !important;
  color: #ffffff !important;
}

.btn-outline-dark {
  border: 1px solid #3c4858;
  color: #3c4858;
  background-color: transparent;
}

.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.focus,
.btn-outline-dark:not(:disabled):not(.disabled):active {
  background-color: #3c4858;
  border-color: #3c4858;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
}

.btn-muted {
  background-color: #8492a6 !important;
  border: 1px solid #8492a6 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
}

.btn-muted:hover,
.btn-muted:focus,
.btn-muted:active,
.btn-muted.active,
.btn-muted.focus {
  background-color: #68788f !important;
  border-color: #68788f !important;
  color: #ffffff !important;
}

.btn-soft-muted {
  background-color: rgba(132, 146, 166, 0.1) !important;
  border: 1px solid rgba(132, 146, 166, 0.1) !important;
  color: #8492a6 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
}

.btn-soft-muted:hover,
.btn-soft-muted:focus,
.btn-soft-muted:active,
.btn-soft-muted.active,
.btn-soft-muted.focus {
  background-color: #8492a6 !important;
  border-color: #8492a6 !important;
  color: #ffffff !important;
}

.btn-outline-muted {
  border: 1px solid #8492a6;
  color: #8492a6;
  background-color: transparent;
}

.btn-outline-muted:hover,
.btn-outline-muted:focus,
.btn-outline-muted:active,
.btn-outline-muted.active,
.btn-outline-muted.focus,
.btn-outline-muted:not(:disabled):not(.disabled):active {
  background-color: #8492a6;
  border-color: #8492a6;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
}

.btn-light {
  background-color: #f8f9fc !important;
  border: 1px solid #f8f9fc !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
  box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light.active,
.btn-light.focus {
  background-color: #d4daed !important;
  border-color: #d4daed !important;
  color: #ffffff !important;
}

.btn-soft-light {
  background-color: rgba(248, 249, 252, 0.1) !important;
  border: 1px solid rgba(248, 249, 252, 0.1) !important;
  color: #f8f9fc !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
  box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

.btn-soft-light:hover,
.btn-soft-light:focus,
.btn-soft-light:active,
.btn-soft-light.active,
.btn-soft-light.focus {
  background-color: #f8f9fc !important;
  border-color: #f8f9fc !important;
  color: #ffffff !important;
}

.btn-outline-light {
  border: 1px solid #f8f9fc;
  color: #f8f9fc;
  background-color: transparent;
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.focus,
.btn-outline-light:not(:disabled):not(.disabled):active {
  background-color: #f8f9fc;
  border-color: #f8f9fc;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
  box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

.btn-blue {
  background-color: $color-primary !important;
  border: 1px solid $color-primary !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active,
.btn-blue.active,
.btn-blue.focus {
  background-color: $color-secondary !important;
  border-color: $color-secondary !important;
  color: #ffffff !important;
}

.btn-soft-blue {
  background-color: rgba(47, 85, 212, 0.1) !important;
  border: 1px solid rgba(47, 85, 212, 0.1) !important;
  color: $color-primary !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-soft-blue:hover,
.btn-soft-blue:focus,
.btn-soft-blue:active,
.btn-soft-blue.active,
.btn-soft-blue.focus {
  background-color: $color-primary !important;
  border-color: $color-primary !important;
  color: #ffffff !important;
}

.btn-outline-blue {
  border: 1px solid $color-primary;
  color: $color-primary;
  background-color: transparent;
}

.btn-outline-blue:hover,
.btn-outline-blue:focus,
.btn-outline-blue:active,
.btn-outline-blue.active,
.btn-outline-blue.focus,
.btn-outline-blue:not(:disabled):not(.disabled):active {
  background-color: $color-primary;
  border-color: $color-primary;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-footer {
  background-color: #202942 !important;
  border: 1px solid #202942 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
}

.btn-footer:hover,
.btn-footer:focus,
.btn-footer:active,
.btn-footer.active,
.btn-footer.focus {
  background-color: #0f131f !important;
  border-color: #0f131f !important;
  color: #ffffff !important;
}

.btn-soft-footer {
  background-color: rgba(32, 41, 66, 0.1) !important;
  border: 1px solid rgba(32, 41, 66, 0.1) !important;
  color: #202942 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
}

.btn-soft-footer:hover,
.btn-soft-footer:focus,
.btn-soft-footer:active,
.btn-soft-footer.active,
.btn-soft-footer.focus {
  background-color: #202942 !important;
  border-color: #202942 !important;
  color: #ffffff !important;
}

.btn-outline-footer {
  border: 1px solid #202942;
  color: #202942;
  background-color: transparent;
}

.btn-outline-footer:hover,
.btn-outline-footer:focus,
.btn-outline-footer:active,
.btn-outline-footer.active,
.btn-outline-footer.focus,
.btn-outline-footer:not(:disabled):not(.disabled):active {
  background-color: #202942;
  border-color: #202942;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
}

.btn {
  padding: 10px 20px;
  outline: none;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 0.5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 600;
  border-radius: 6px;
}

.btn:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn.btn-sm {
  padding: 8px 16px;
  font-size: 12px;
}

.btn.btn-lg {
  padding: 14px 30px;
  font-size: 16px;
}

.btn.searchbtn {
  padding: 6px 20px;
}

.btn.btn-pills {
  border-radius: 30px;
}

.btn.btn-light {
  color: #3c4858 !important;
  border: 1px solid #dee2e6 !important;
}

.btn.btn-light:hover,
.btn.btn-light:focus,
.btn.btn-light:active,
.btn.btn-light.active,
.btn.btn-light.focus {
  background-color: #d4daed !important;
  color: #3c4858 !important;
}

.btn.btn-soft-light {
  color: rgba(60, 72, 88, 0.5) !important;
  border: 1px solid #dee2e6 !important;
}

.btn.btn-soft-light:hover,
.btn.btn-soft-light:focus,
.btn.btn-soft-light:active,
.btn.btn-soft-light.active,
.btn.btn-soft-light.focus {
  color: #3c4858 !important;
}

.btn.btn-outline-light {
  border: 1px solid #dee2e6 !important;
  color: #3c4858 !important;
  background-color: transparent;
}

.btn.btn-outline-light:hover,
.btn.btn-outline-light:focus,
.btn.btn-outline-light:active,
.btn.btn-outline-light.active,
.btn.btn-outline-light.focus {
  background-color: #f8f9fc !important;
}

.btn.btn-icon {
  height: 36px;
  width: 36px;
  line-height: 34px;
  padding: 0;
}

.btn.btn-icon .icons {
  height: 16px;
  width: 16px;
  font-size: 16px;
}

.btn.btn-icon.btn-lg {
  height: 48px;
  width: 48px;
  line-height: 46px;
}

.btn.btn-icon.btn-lg .icons {
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.btn.btn-icon.btn-sm {
  height: 30px;
  width: 30px;
  line-height: 28px;
}

button:not(:disabled) {
  outline: none;
}

.shadow {
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
}

.shadow-lg {
  -webkit-box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
  box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
}

.shadow-md {
  -webkit-box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
  box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
}

.badge {
  letter-spacing: 0.5px;
  padding: 4px 8px;
  font-weight: 600;
  line-height: 11px;
  position: absolute;
  margin-left: 8px;
  margin-top: 8px;
  z-index: 1;
}

.badge.badge-link:hover {
  color: #ffffff !important;
}

.dropdown-primary .dropdown-menu .dropdown-item:hover,
.dropdown-primary .dropdown-menu .dropdown-item.active,
.dropdown-primary .dropdown-menu .dropdown-item:active,
.dropdown-primary .dropdown-menu .dropdown-item.focus,
.dropdown-primary .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: $color-primary !important;
}

.dropdown-secondary .dropdown-menu .dropdown-item:hover,
.dropdown-secondary .dropdown-menu .dropdown-item.active,
.dropdown-secondary .dropdown-menu .dropdown-item:active,
.dropdown-secondary .dropdown-menu .dropdown-item.focus,
.dropdown-secondary .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #5a6d90 !important;
}

.dropdown-success .dropdown-menu .dropdown-item:hover,
.dropdown-success .dropdown-menu .dropdown-item.active,
.dropdown-success .dropdown-menu .dropdown-item:active,
.dropdown-success .dropdown-menu .dropdown-item.focus,
.dropdown-success .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #2eca8b !important;
}

.dropdown-warning .dropdown-menu .dropdown-item:hover,
.dropdown-warning .dropdown-menu .dropdown-item.active,
.dropdown-warning .dropdown-menu .dropdown-item:active,
.dropdown-warning .dropdown-menu .dropdown-item.focus,
.dropdown-warning .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #f17425 !important;
}

.dropdown-info .dropdown-menu .dropdown-item:hover,
.dropdown-info .dropdown-menu .dropdown-item.active,
.dropdown-info .dropdown-menu .dropdown-item:active,
.dropdown-info .dropdown-menu .dropdown-item.focus,
.dropdown-info .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #17a2b8 !important;
}

.dropdown-danger .dropdown-menu .dropdown-item:hover,
.dropdown-danger .dropdown-menu .dropdown-item.active,
.dropdown-danger .dropdown-menu .dropdown-item:active,
.dropdown-danger .dropdown-menu .dropdown-item.focus,
.dropdown-danger .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #e43f52 !important;
}

.dropdown-dark .dropdown-menu .dropdown-item:hover,
.dropdown-dark .dropdown-menu .dropdown-item.active,
.dropdown-dark .dropdown-menu .dropdown-item:active,
.dropdown-dark .dropdown-menu .dropdown-item.focus,
.dropdown-dark .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #3c4858 !important;
}

.dropdown-muted .dropdown-menu .dropdown-item:hover,
.dropdown-muted .dropdown-menu .dropdown-item.active,
.dropdown-muted .dropdown-menu .dropdown-item:active,
.dropdown-muted .dropdown-menu .dropdown-item.focus,
.dropdown-muted .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #8492a6 !important;
}

.dropdown-light .dropdown-menu .dropdown-item:hover,
.dropdown-light .dropdown-menu .dropdown-item.active,
.dropdown-light .dropdown-menu .dropdown-item:active,
.dropdown-light .dropdown-menu .dropdown-item.focus,
.dropdown-light .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #f8f9fc !important;
}

.dropdown-blue .dropdown-menu .dropdown-item:hover,
.dropdown-blue .dropdown-menu .dropdown-item.active,
.dropdown-blue .dropdown-menu .dropdown-item:active,
.dropdown-blue .dropdown-menu .dropdown-item.focus,
.dropdown-blue .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: $color-primary !important;
}

.dropdown-footer .dropdown-menu .dropdown-item:hover,
.dropdown-footer .dropdown-menu .dropdown-item.active,
.dropdown-footer .dropdown-menu .dropdown-item:active,
.dropdown-footer .dropdown-menu .dropdown-item.focus,
.dropdown-footer .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #202942 !important;
}

.btn-group .dropdown-toggle:after {
  content: "";
  position: relative;
  right: -4px;
  top: -2px;
  border: solid #ffffff;
  border-radius: 0.5px;
  border-width: 0 2px 2px 0;
  padding: 3px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left: 0;
  vertical-align: 0;
}

.btn-group .dropdown-menu {
  margin-top: 10px;
  border: 0;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

.btn-group .dropdown-menu:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 45px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 7px solid #495057;
  border-radius: 0.5px;
  border-color: transparent transparent #ffffff #ffffff;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-box-shadow: -2px 2px 2px -1px rgba(22, 28, 45, 0.15);
  box-shadow: -2px 2px 2px -1px rgba(22, 28, 45, 0.15);
}

.alert-primary {
  background-color: rgba(47, 85, 212, 0.9);
  color: #ffffff;
  border-color: $color-primary;
}

.alert-primary .alert-link {
  color: #122258;
}

.alert-outline-primary {
  background-color: #ffffff;
  color: $color-primary;
  border-color: $color-primary;
}

.alert-secondary {
  background-color: rgba(90, 109, 144, 0.9);
  color: #ffffff;
  border-color: #5a6d90;
}

.alert-secondary .alert-link {
  color: #1f2632;
}

.alert-outline-secondary {
  background-color: #ffffff;
  color: #5a6d90;
  border-color: #5a6d90;
}

.alert-success {
  background-color: rgba(46, 202, 139, 0.9);
  color: #ffffff;
  border-color: #2eca8b;
}

.alert-success .alert-link {
  color: #124d35;
}

.alert-outline-success {
  background-color: #ffffff;
  color: #2eca8b;
  border-color: #2eca8b;
}

.alert-warning {
  background-color: rgba(241, 116, 37, 0.9);
  color: #ffffff;
  border-color: #f17425;
}

.alert-warning .alert-link {
  color: #753208;
}

.alert-outline-warning {
  background-color: #ffffff;
  color: #f17425;
  border-color: #f17425;
}

.alert-info {
  background-color: rgba(23, 162, 184, 0.9);
  color: #ffffff;
  border-color: #17a2b8;
}

.alert-info .alert-link {
  color: #062a30;
}

.alert-outline-info {
  background-color: #ffffff;
  color: #17a2b8;
  border-color: #17a2b8;
}

.alert-danger {
  background-color: rgba(228, 63, 82, 0.9);
  color: #ffffff;
  border-color: #e43f52;
}

.alert-danger .alert-link {
  color: #79111d;
}

.alert-outline-danger {
  background-color: #ffffff;
  color: #e43f52;
  border-color: #e43f52;
}

.alert-dark {
  background-color: rgba(60, 72, 88, 0.9);
  color: #ffffff;
  border-color: #3c4858;
}

.alert-dark .alert-link {
  color: black;
}

.alert-outline-dark {
  background-color: #ffffff;
  color: #3c4858;
  border-color: #3c4858;
}

.alert-muted {
  background-color: rgba(132, 146, 166, 0.9);
  color: #ffffff;
  border-color: #8492a6;
}

.alert-muted .alert-link {
  color: #3d4654;
}

.alert-outline-muted {
  background-color: #ffffff;
  color: #8492a6;
  border-color: #8492a6;
}

.alert-light {
  background-color: rgba(248, 249, 252, 0.9);
  color: #ffffff;
  border-color: #f8f9fc;
}

.alert-light .alert-link {
  color: #8d9dce;
}

.alert-outline-light {
  background-color: #ffffff;
  color: #f8f9fc;
  border-color: #f8f9fc;
}

.alert-blue {
  background-color: rgba(47, 85, 212, 0.9);
  color: #ffffff;
  border-color: $color-primary;
}

.alert-blue .alert-link {
  color: #122258;
}

.alert-outline-blue {
  background-color: #ffffff;
  color: $color-primary;
  border-color: $color-primary;
}

.alert-footer {
  background-color: rgba(32, 41, 66, 0.9);
  color: #ffffff;
  border-color: #202942;
}

.alert-footer .alert-link {
  color: black;
}

.alert-outline-footer {
  background-color: #ffffff;
  color: #202942;
  border-color: #202942;
}

.alert {
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
}

.alert.alert-light {
  background-color: white;
  color: #3c4858;
  border-color: #e9ecef;
}

.alert.alert-dismissible {
  padding-right: 30px;
}

.alert.alert-dismissible .btn-close {
  top: 10px;
  right: 10px;
  padding: 0px;
}

.alert.alert-pills {
  border-radius: 30px;
  display: inline-block;
}

.alert.alert-pills .content {
  font-weight: 600;
}

.avatar.avatar-ex-sm {
  height: auto;
  max-width: 100%;
}

.avatar.avatar-md-sm {
  height: 45px;
  width: 45px;
}

.avatar.avatar-small {
  height: 65px;
  width: 65px;
}

.avatar.avatar-md-md {
  height: 80px;
  width: 80px;
}

.avatar.avatar-medium {
  height: 110px;
  width: 110px;
}

.avatar.avatar-large {
  height: 140px;
  width: 140px;
}

.avatar.avatar-ex-large {
  height: 180px;
  width: 180px;
}

.nav-pills {
  margin-bottom: 15px;
  background: #f4f6fa;
}

.nav-pills .nav-link {
  color: #495057 !important;
  padding: 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.nav-pills .nav-link.nav-link-alt {
  color: #ffffff !important;
}

.nav-pills .nav-link.nav-link-alt.active {
  background: #3c4858 !important;
}

.nav-pills .nav-link.active {
  background: $color-primary;
  color: #ffffff !important;
}

.nav-pills .nav-link.active .tab-para {
  color: rgba(255, 255, 255, 0.65) !important;
}

.form-label,
.form-check-label {
  font-weight: 700;
  font-size: 14px;
}

.form-control {
  border: 1px solid #dee2e6;
  font-size: 14px;
  line-height: 33px;
  background-color: #ffffff !important;
  border-radius: 6px;
}

.form-control:focus {
  border-color: $color-primary;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #dee2e6 !important;
  cursor: not-allowed;
}

.form-check-input:focus {
  border-color: $color-primary;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-check-input.form-check-input:checked {
  background-color: $color-primary;
  border-color: $color-primary;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table-responsive .table td,
.table-responsive .table th {
  vertical-align: middle;
}

.table-responsive .table.table-nowrap th,
.table-responsive .table.table-nowrap td {
  white-space: nowrap;
}

.table-responsive .table-center th {
  vertical-align: middle !important;
}

.table-responsive .table-center tbody tr:hover {
  color: #3c4858;
  background-color: #f8f9fa;
}

.table-responsive .table-center.invoice-tb th,
.table-responsive .table-center.invoice-tb td {
  text-align: end;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(45deg, #ffffff, #ffffff);
  z-index: 9999999;
}

#preloader #status {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#preloader #status .spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

#preloader #status .spinner .double-bounce1,
#preloader #status .spinner .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: $color-primary;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

#preloader #status .spinner .double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.back-to-top {
  z-index: 99;
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: none;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.back-to-top .icons {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.back-to-top:hover {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.back-to-top:hover .icons {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.fea {
  stroke-width: 1.8;
}

.fea.icon-sm {
  height: 16px;
  width: 16px;
}

.fea.icon-ex-md {
  height: 20px;
  width: 20px;
}

.fea.icon-m-md {
  height: 28px;
  width: 28px;
}

.fea.icon-md {
  height: 35px;
  width: 35px;
}

.fea.icon-lg {
  height: 42px;
  width: 42px;
}

.fea.icon-ex-lg {
  height: 52px;
  width: 52px;
}

[class^="uil-"]:before,
[class*=" uil-"]:before {
  margin: 0;
}

.uim-svg {
  vertical-align: 0 !important;
}

body {
  font-family: "Cairo", sans-serif;
  overflow-x: hidden !important;
  font-weight: normal;
  color: #161c2d;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cairo", sans-serif;
  line-height: 1.5;
  font-weight: bold;
}

::-moz-selection {
  background: rgba(47, 85, 212, 0.9);
  color: #ffffff;
}

::selection {
  background: rgba(47, 85, 212, 0.9);
  color: #ffffff;
}

a {
  text-decoration: none !important;
}

p {
  line-height: 1.6;
}
@media (min-width: 992px) {
  .text-lg-start {
    text-align: start !important;
  }
  .text-lg-end {
    text-align: end !important;
  }
}
//Title
.title-heading {
  line-height: 26px;
  .heading {
    font-size: 35px !important;
    letter-spacing: 1px;
    @media (max-width: 768px) {
      font-size: 25px !important;
    }
  }
  .para-desc {
    font-size: 15px;
  }
}

.section {
  padding: 60px 0;
  position: relative;
}

@media (max-width: 768px) {
  .section {
    padding: 60px 0;
  }
}

.section-title .title {
  letter-spacing: 0.5px;
  font-size: 27px !important;
}
.section-title p {
  font-size: 15px;
}

@media (max-width: 768px) {
  .section-title .title {
    font-size: 24px !important;
  }
}

.cdk-visually-hidden {
  display: none !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  width: auto;
  border-top: 5px solid transparent;
  border-bottom: 4px solid transparent;
  padding: 10px 0;
}

.login-error {
  color: red;
  position: relative;
}

.login-error.login-error-hidden {
  opacity: 0;
}

.login-error.login-error-visible {
  opacity: 1;
  font-weight: 500;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0003;
  z-index: 99999;
}

.popup .table-container {
  border-radius: 10px;
  width: 70%;
  min-width: 320px;
}

.card-body ngx-daterangepicker-material .md-drppicker {
  width: 596px;
  font-family: "Cairo", sans-serif;
  font-weight: normal;

  .ranges button.active {
    background-color: $color-primary;
  }

  .available {
    color: $color-secondary;

    &.off {
      color: #999999;
    }

    &.active {
      background-color: $color-primary;
      color: #ffffff;
    }
  }

  .btn {
    background-color: $color-primary;
  }
}

@media (max-width: 667px) {
  .card-body ngx-daterangepicker-material .md-drppicker {
    width: 320px;
    font-family: "Cairo", sans-serif;
    font-weight: normal;
  }
}

.cdk-global-scrollblock {
  overflow-y: auto !important;
}

.ngx-dropdown-container .ngx-dropdown-button {
  border: 1px solid #dee2e6 !important;
  border-radius: 6px !important;
  padding: 0.375rem 0.75rem !important;
  .nsdicon-angle-down {
    top: 2px;
  }
}

.link-primary {
  cursor: pointer;
}

.global-loading-dialog {
  width: 90px;
  height: 90px;
  overflow: hidden !important;
  border-radius: 100%;
  padding: 0;
}

.mat-sort-header-container {
  justify-content: center !important;
}

.sb-moon {
  justify-content: center;
  // share button wrapper
  .sb-wrapper {
    cursor: pointer;
    outline: 0;
    border: none;
    border-radius: 5px;
    padding: 0;
    -webkit-print-color-adjust: exact;
    color: #ffffff;
    min-width: 50px;
    // You can get default button color using the CSS variable
    background-color: var(--button-color);
    // Content wrapper
    .sb-content {
      // Icon wrapper
      .sb-icon {
      }
      // Text wrapper
      .sb-text {
      }
    }
    // For conditional styles
    // E.g. Apply when icon, text are shown
    &.sb-show-icon.sb-show-text {
      // Icon wrapper
      .sb-icon {
      }
      // Text wrapper
      .sb-text {
      }
    }
  }
}

.form-number-control,
.form-number-control[readonly] {
  background-color: #fff;
}

.form-number-control {
  border: 1px solid #dee2e6;
  font-size: 14px;
  border-radius: 6px;
  padding: 6px 0px;
  outline: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.cdk-overlay-pane.owl-dt-popup {
  bottom: unset !important;
}

.image-preview {
  max-width: 100%;

  img {
    object-fit: cover;

    &:hover {
      object-fit: contain;
    }
  }
}

.mat-checkbox-layout {
  margin-right: 20px;
}

.mat-form-field {
  margin: 0.25rem 0 !important;
  background-color: #ffffff !important;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 !important;
}
////////////// RTL ///////////
body.dir-rtl {
  direction: rtl;
  font-weight: normal;
  font-family: "Cairo", sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
 // .content-container,
  table
 // .mat-checkbox,
 // .mat-radio-button,
 // .mat-label,
 // .mat-form-field-label
  {
    font-family: "Cairo", sans-serif !important;
    font-weight: bold;
  }
  h1 {
    font-size: 30px;
  }

  p {
    font-size: 14px;
  }
  .no-rtl {
    direction: ltr;
    unicode-bidi: embed;
  }

  .border-left {
    border-left: unset !important;
    border-right: 1px solid #e9ecef;
  }

  .border-end {
    border-right: unset !important;
    border-left: 1px solid #e9ecef;
  }

  .pe-2 {
    padding-right: unset !important;
    padding-left: 0.5rem !important;
  }

  .ps-2 {
    padding-left: unset !important;
    padding-right: 0.5rem !important;
  }

  .pe-5 {
    padding-right: unset !important;
    padding-left: 3rem !important;
  }

  .ps-5 {
    padding-left: unset !important;
    padding-right: 3rem !important;
  }

  .p-3.pe-5 {
    padding: 1rem 1rem 1rem 3rem !important;
  }

  .me-1 {
    margin-right: unset !important;
    margin-left: 0.25rem !important;
  }

  .ms-1 {
    margin-left: unset !important;
    margin-right: 0.25rem !important;
  }

  .me-2 {
    margin-right: unset !important;
    margin-left: 0.5rem !important;
  }

  .ms-2 {
    margin-left: unset !important;
    margin-right: 0.5rem !important;
  }

  .me-3 {
    margin-right: unset !important;
    margin-left: 1rem !important;
  }

  .ms-3 {
    margin-left: unset !important;
    margin-right: 1rem !important;
  }

  .me-4 {
    margin-right: unset !important;
    margin-left: 2rem !important;
  }

  .ms-4 {
    margin-left: unset !important;
    margin-right: 2rem !important;
  }

  .ms-auto {
    margin-left: unset !important;
    margin-right: auto !important;
  }

  .me-auto {
    margin-right: unset !important;
    margin-left: auto !important;
  }

  .text-start {
    text-align: right !important;
  }

  .back-to-top {
    right: unset;
    left: 30px;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    direction: ltr;
  }

  .mat-form-field-label-wrapper,
  .mat-input-element {
    text-align: right;
  }

  .mat-datepicker-toggle,
  .mat-datepicker-content .mat-calendar-next-button,
  .mat-datepicker-content .mat-calendar-previous-button {
    right: unset !important;
    left: -0.5rem;
  }

  .mat-radio-label-content {
    padding-left: 0px;
    padding-right: 8px;
  }

  .mat-checkbox-layout {
    margin-right: auto;
    margin-left: 20px;
  }

  .mat-checkbox-inner-container {
    margin: auto;
    margin-left: 8px;
  }

  .form-select {
    background-position: left 0.75rem center;
    padding: 0.375rem 0.75rem 0.375rem 2.25rem;
  }

  .mat-focused mat-label,
  .mat-form-field-should-float mat-label {
    margin-right: 32px;
  }

  #home-start-bg {
    background: url("../images/background/bg-reversed.jpg") 55% 65% !important;
  }

  .fas.fa-chevron-right {
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .accordion-button::after {
    margin-left: unset;
    margin-right: auto;
  }

  .TicketLeft {
    text-align: right;
  }

  #collapseMenu {
    right: unset;
    left: 20px;
  }

  .sub-a {
    padding: 10px 30px 10px 0px !important;
  }

  .dashboard-top-navbar a {
    border-right: unset !important;
    border-left: 1px solid #eaeaea;

    &:last-child {
      border-left: unset;
    }
  }

  .customer-support-container {
    right: auto;
    left: 0px;
  }

  .verify {
    left: auto;
    right: 3%;
  }

  .verify-account-container {
    left: auto;
    right: 3%;
  }

  #advance-sales {
    .card img {
      left: 15px;
      right: unset;
    }

    h4 {
      font-size: 1.2rem;
    }

    .card-1,
    .card-2,
    .card-3 {
      background-position: left;
    }
  }

  #manage-products {
    .add-button-text {
      padding: 10px 10px 10px 50px;
    }

    .add-button {
      margin-left: unset;
      margin-right: -40px;
      padding: 0px 15px;
    }

    .service {
      margin-right: unset;
      margin-left: 4px;
    }
  }

  .input-group {
    .input-group-append.input-verify {
      padding-right: 4px;
      padding-left: 8px;

      mat-icon {
        vertical-align: middle;
      }
    }
  }

  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px solid #ebedf2;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
      .valid-feedback
    ):not(.invalid-tooltip):not(.invalid-feedback) {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border: 1px solid #ebedf2;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .widget ul.list-unstyled {
    padding-right: 0px;
  }

  .form-check .form-check-input {
    float: right;
  }

  .form-label,
  .form-check-label {
    margin-right: 0.75rem;
  }

  .mat-paginator-range-actions {
    direction: ltr;
    unicode-bidi: embed;
  }

  .cdk-global-overlay-wrapper {
    direction: rtl;
  }

  .mat-form-field {
    text-align: right !important;
  }

  .form-check .form-check-logo {
    float: left !important;
    margin-left: -1.5rem;
  }
  .form-check .form-check-logo:nth-of-type(2) {
    margin: -0.25rem 25px;
  }

  .form-check .form-check-input {
    margin-right: -1em;
  }

  .form-check #flexCheckDefault.form-check-input {
    margin-right: 0rem;
    margin-left: 0.75rem;
  }

  .form-check #flexCheckLabel {
    margin-left: 0rem;
    display: block;
  }
  .download .mockup-download {
    left: -100px !important;
  }
  @media (min-width: 992px) {
    #topnav .megamenu {
      left: 60% !important;
    }
  }

  .work-process:after {
    left: -35%;
    background: url(../images/about/processRTL.png) center center no-repeat;
  }
  .plans .content .plan a {
    margin: 26px -65px !important;
  }
  ul.megamenu {
    direction: rtl;
  }

  .classic-saas-image .bg-saas-shape {
    &:after {
      @media (max-width: 767px) {
        left: -20rem !important;
        height: 20rem !important;
      }
    }
  }
  .downloadApp .download {
    background-image: url(../../assets/images/background/BgRTL.png) !important;
  }
  .has-submenu .menu-arrow {
    right: 75px !important;
  }
}

.mat-tab-label-container {
  z-index: 0 !important;
}

/*start style*/
.couterlist a > div {
  display: flex;
}
.couterlist img {
  width: 22px;
  margin: 0 10px;
}
.couterlist img + div {
  line-height: 47px;
  font-size: 14px;
  white-space: nowrap;
}
.couterlist .add-step {
  background: #ffffff;
  box-shadow: 0px 5px 52px rgb(0 0 0 / 8%);
  line-height: 31px;
  align-items: center;
  padding: 0;
  margin: 7px auto;
}
.numberleft {
  background-color: rgba(0, 0, 0, 0.15);
  color: #8d8d8d;
  width: 30px;
  display: block;
  line-height: 47px;
}
.couterlist .add-step.primarybock .numberleft {
  color: #fff;
}
.couterlist .add-step.primarybock {
  background: #0f75bc;
  color: #fff;
}
.couterlist .add-step.primarybock svg {
  color: #fff !important;
}
.couterlist .add-step.primarybock .text-primary {
  color: #fff !important;
}
.mat-form-field-subscript-wrapper {
  margin-top: 25px;
  margin-left: -10px;
}
.cardbg-white {
  background: #ffffff;
  box-shadow: 0px 5px 52px rgba(0, 0, 0, 0.08);
  padding: 15px;
}
.nmbcol {
  padding: 0;
}
.cardbg-white label {
  font-size: 15px;
}
.mat-radio-button {
  background: #eeee;
  padding: 6px;
  width: 95px;
  margin-right: 0px !important;
  text-align: center;
}
.mat-radio-button > label {
  margin-left: auto;
  display: inline-block;
  margin-right: auto;
}
.mat-radio-button > label .mat-radio-label-content {
  padding-left: 0;
}
.mat-radio-button.mat-accent.mat-radio-checked {
  background: #15d828;
  color: #fff;
}
.mat-accent + .mat-radio-button.mat-accent.mat-radio-checked {
  background: #ff6c6c;
  color: #fff;
}
.mat-accent .mat-radio-label .mat-radio-container {
  display: none;
}
.columcard > .ng-star-inserted {
  width: calc(33.33% - 14px);
  float: left;
  display: flex;
}
@media (max-width: 767px) {
  div.columcard > .ng-star-inserted {
    width: 100%;
  }
}
.columcard > .ng-star-inserted {
  width: calc(33.33% - 14px);
  float: left;
  display: flex;
}
.columcard .mat-checkbox {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  padding: 10px 15px;
  margin: 7px;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled) {
  background: #eaeaea;
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgb(173 171 171 / 87%);
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: #45aae2 !important;
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #45aae2 !important;
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: #45aae2 !important;
}

.image-field-component {
  .dottaedbord.border {
    background: #f1f1f1;
    border: 1px dashed #45aae2 !important;
    border-radius: 8px;
    p {
      font-size: 14px;
      letter-spacing: normal;
      padding-top: 5px;
    }
  }
}
.maplocator {
  .btnmap {
    padding: 10px 20px;
    border-radius: 0 8px 8px 0;
  }
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  top: 0 !important;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0;
  min-height: 40px;
}
textarea.form-control {
  min-height: calc(3.5em + 0.75rem + 2px);
}
.mat-input-element {
  min-height: 40px;
}
div .ngx-dropdown-container .ngx-dropdown-button {
  line-height: 46px !important;
  padding: 0 0.75rem !important;
  overflow-y: hidden;
}
.btn-grey {
  background: #515151;
  border-color: #515151;
  color: #fff;
}
.btn-grey:hover {
  background: #2f2f2f;
  border-color: #2f2f2f;
  color: #fff;
}
.btncleardes .btn {
  font-size: 15px;
}
.btncleardes .btn {
  font-size: 14px;
  font-weight: 500;
}
.imguploaded {
  display: inline-flex;
  align-items: center;
}
.btnploaded button.btn {
  font-size: 13px;
  font-weight: 500;
}
.text-right {
  text-align: right;
}
.mat-icon.delection.text-danger {
  position: absolute;
  bottom: 0px;
  right: 0;
  background: #e43f52;
  width: 50px;
  color: #fff !important;
  height: 50px;
  text-align: center;
  line-height: 60px;
  border-radius: 50px 0 0;
  padding-left: 18px;
}
.relativebody {
  position: relative;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: #0f74bb;
}
a.btn.ps-0.text-danger.icondelete {
  background: #e24545;
  padding: 0;
  color: #fff !important;
  text-align: center;
  margin: 0 15px;
  width: 46px;
  height: 46px;
  line-height: 46px;
}
a.btn.icondedit {
  background: #32df43;
  padding: 0;
  color: #fff !important;
  text-align: center;
  margin: 0 15px;
  width: 46px;
  height: 46px;
  line-height: 46px;
}
.flexrow {
  .mat-slide-toggle {
    .mat-slide-toggle-label {
      display: block;
      text-align: center;
      .mat-slide-toggle-bar {
        margin-bottom: 5px;
        margin-left: 7px;
      }
    }
  }
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(69 170 226 / 54%);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #45aae2;
}
.mat-slide-toggle .mat-slide-toggle-ripple > * {
  background: rgb(69 170 226 / 54%) !important;
}

body:lang(ar) .btn-back {
  left: 66px;
  right: auto;
}

.bg-overlay {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.shape {
  position: absolute;
  pointer-events: none;
  right: 0;
  bottom: -2px;
  left: 0;
}

.shape > svg {
  -webkit-transform: scale(2);
  transform: scale(2);
  width: 100%;
  height: auto;
  -webkit-transform-origin: top center;
  transform-origin: top center;
}
.owl-prev,
.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent !important;
  color: #dddddd !important;
  font-size: 20px !important;
}

.owl-prev {
  left: -2rem;
}

.owl-next {
  right: -2rem;
}

/* Target the dropdown panel */
.ng-select .ng-dropdown-panel {
  background-color: #f0f0f0;
  border: 1px solid #dee2e6;
  border-radius: 6px;
}

/* Target the dropdown items */
.ng-select .ng-option {
  background-color: #ffffff;
  padding: 5px;
}

.ng-select .ng-option:hover {
  background-color: #e0e0e0; /* Background color when hovering over an item */
}

.ng-select .ng-select-container .ng-placeholder {
  color: #999;
}

.custom-amount {
  .mat-form-field {
    margin-top: 8px !important;
    display: block !important;
  }
  .mat-input-element {
    height: 50px;
  }
  .mat-form-field-wrapper{
    padding: 0;
  }
}

.mt-element-step .step-default .mt-step-col {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.mt-element-step .step-default .mt-step-number {
  font-size: 26px;
  border-radius: 50% !important;
  display: inline-block;
  margin: auto;
  padding: 3px 14px;
  margin-bottom: 20px;
}

.mt-element-step .step-default .mt-step-title {
  font-size: 30px;
  font-weight: 100;
}

.mt-element-step .step-default .active {
  background-color: var(--color-primary) !important;
}
.mt-element-step .step-default .active .mt-step-number {
  color: var(--color-primary) !important;
}
.mt-element-step .step-default .active .mt-step-title,
.mt-element-step .step-default .active .mt-step-content {
  color: #fff !important;
}

.mt-element-step .step-default .done {
  background-color: #26c281 !important;
}
.mt-element-step .step-default .done .mt-step-number {
  color: #26c281 !important;
}
.mt-element-step .step-default .done .mt-step-title,
.mt-element-step .step-default .done .mt-step-content {
  color: #fff !important;
}

.mt-element-step .step-default .error {
  background-color: #e7505a !important;
}
.mt-element-step .step-default .error .mt-step-number {
  color: #e7505a !important;
}
.mt-element-step .step-default .error .mt-step-title,
.mt-element-step .step-default .error .mt-step-content {
  color: #fff !important;
}

.mt-element-step .step-thin .mt-step-col {
  padding-top: 10px;
  padding-bottom: 10px;
}

.mt-element-step .step-thin .mt-step-number {
  font-size: 26px;
  border-radius: 50% !important;
  float: left;
  margin: auto;
  padding: 3px 14px;
}

.mt-element-step .step-thin .mt-step-title {
  font-size: 24px;
  font-weight: 100;
  padding-left: 60px;
  margin-top: -4px;
}

.mt-element-step .step-thin .mt-step-content {
  padding-left: 60px;
  margin-top: -5px;
}

.mt-element-step .step-thin .active {
  background-color: var(--color-primary) !important;
}
.mt-element-step .step-thin .active .mt-step-number {
  color: var(--color-primary) !important;
}
.mt-element-step .step-thin .active .mt-step-title,
.mt-element-step .step-thin .active .mt-step-content {
  color: #fff !important;
}

.mt-element-step .step-thin .done {
  background-color: #26c281 !important;
}
.mt-element-step .step-thin .done .mt-step-number {
  color: #26c281 !important;
}
.mt-element-step .step-thin .done .mt-step-title,
.mt-element-step .step-thin .done .mt-step-content {
  color: #fff !important;
}

.mt-element-step .step-thin .error {
  background-color: #e7505a !important;
}
.mt-element-step .step-thin .error .mt-step-number {
  color: #e7505a !important;
}
.mt-element-step .step-thin .error .mt-step-title,
.mt-element-step .step-thin .error .mt-step-content {
  color: #fff !important;
}

.mt-element-step .step-background .mt-step-col {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  height: 160px;
}

.mt-element-step .step-background .mt-step-number {
  font-size: 200px;
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 0.79em;
  color: #dae1e4;
  z-index: 4;
}

.mt-element-step .step-background .mt-step-title {
  font-size: 30px;
  font-weight: 100;
  text-align: right;
  padding-right: 25%;
  z-index: 5;
  position: relative;
}

.mt-element-step .step-background .mt-step-content {
  text-align: right;
  padding-right: 25%;
  z-index: 5;
  position: relative;
}

.mt-element-step .step-background .active {
  background-color: var(--color-primary) !important;
}
.mt-element-step .step-background .active .mt-step-number {
  color: #2ab4c0 !important;
}
.mt-element-step .step-background .active .mt-step-title,
.mt-element-step .step-background .active .mt-step-content {
  color: #fff !important;
}

.mt-element-step .step-background .done {
  background-color: #26c281 !important;
}
.mt-element-step .step-background .done .mt-step-number {
  color: #22ad73 !important;
}
.mt-element-step .step-background .done .mt-step-title,
.mt-element-step .step-background .done .mt-step-content {
  color: #fff !important;
}

.mt-element-step .step-background .error {
  background-color: #e7505a !important;
}
.mt-element-step .step-background .error .mt-step-number {
  color: #e43a45 !important;
}
.mt-element-step .step-background .error .mt-step-title,
.mt-element-step .step-background .error .mt-step-content {
  color: #fff !important;
}

.mt-element-step .step-background-thin .mt-step-col {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
}

.mt-element-step .step-background-thin .mt-step-number {
  font-size: 120px;
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 0.79em;
  color: #dae1e4;
  z-index: 4;
}

.mt-element-step .step-background-thin .mt-step-title {
  font-size: 30px;
  font-weight: 100;
  text-align: right;
  padding-right: 25%;
  z-index: 5;
  position: relative;
}

.mt-element-step .step-background-thin .mt-step-content {
  text-align: right;
  position: relative;
  padding-right: 25%;
  z-index: 5;
}

.mt-element-step .step-background-thin .active {
  background-color: var(--color-primary) !important;
}
.mt-element-step .step-background-thin .active .mt-step-number {
  color: #2ab4c0 !important;
}
.mt-element-step .step-background-thin .active .mt-step-title,
.mt-element-step .step-background-thin .active .mt-step-content {
  color: #fff !important;
}

.mt-element-step .step-background-thin .done {
  background-color: #26c281 !important;
}
.mt-element-step .step-background-thin .done .mt-step-number {
  color: #22ad73 !important;
}
.mt-element-step .step-background-thin .done .mt-step-title,
.mt-element-step .step-background-thin .done .mt-step-content {
  color: #fff !important;
}

.mt-element-step .step-background-thin .error {
  background-color: #e7505a !important;
}
.mt-element-step .step-background-thin .error .mt-step-number {
  color: #e43a45 !important;
}
.mt-element-step .step-background-thin .error .mt-step-title,
.mt-element-step .step-background-thin .error .mt-step-content {
  color: #fff !important;
}

.mt-element-step .step-no-background .mt-step-col {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.mt-element-step .step-no-background .mt-step-number {
  font-size: 26px;
  border-radius: 50% !important;
  display: inline-block;
  margin: auto;
  padding: 3px 14px;
  margin-bottom: 20px;
  border: 1px solid;
  border-color: #e5e5e5;
}

.mt-element-step .step-no-background .mt-step-title {
  font-size: 30px;
  font-weight: 100;
}

.mt-element-step .step-no-background .active .mt-step-number {
  color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
  font-weight: 700;
}

.mt-element-step .step-no-background .active .mt-step-title,
.mt-element-step .step-no-background .active .mt-step-content {
  color: var(--color-primary) !important;
  font-weight: 700;
}

.mt-element-step .step-no-background .done .mt-step-number {
  color: #26c281 !important;
  border-color: #26c281 !important;
  font-weight: 700;
}

.mt-element-step .step-no-background .done .mt-step-title,
.mt-element-step .step-no-background .done .mt-step-content {
  color: #26c281 !important;
  font-weight: 700;
}

.mt-element-step .step-no-background .error .mt-step-number {
  color: #e7505a !important;
  border-color: #e7505a !important;
  font-weight: 700;
}

.mt-element-step .step-no-background .error .mt-step-title,
.mt-element-step .step-no-background .error .mt-step-content {
  color: #e7505a !important;
  font-weight: 700;
}

.mt-element-step .step-no-background-thin .mt-step-col {
  padding-top: 10px;
  padding-bottom: 10px;
}

.mt-element-step .step-no-background-thin .mt-step-number {
  font-size: 26px;
  border-radius: 50% !important;
  float: left;
  margin: auto;
  padding: 3px 14px;
  border: 1px solid;
  border-color: #e5e5e5;
}

.mt-element-step .step-no-background-thin .mt-step-title {
  font-size: 24px;
  font-weight: 100;
  padding-left: 60px;
  margin-top: -4px;
}

.mt-element-step .step-no-background-thin .mt-step-content {
  padding-left: 60px;
  margin-top: -5px;
}

.mt-element-step .step-no-background-thin .active .mt-step-number {
  color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
  font-weight: 700;
}

.mt-element-step .step-no-background-thin .active .mt-step-title,
.mt-element-step .step-no-background-thin .active .mt-step-content {
  color: var(--color-primary) !important;
  font-weight: 700;
}

.mt-element-step .step-no-background-thin .done .mt-step-number {
  color: #26c281 !important;
  border-color: #26c281 !important;
  font-weight: 700;
}

.mt-element-step .step-no-background-thin .done .mt-step-title,
.mt-element-step .step-no-background-thin .done .mt-step-content {
  color: #26c281 !important;
  font-weight: 700;
}

.mt-element-step .step-no-background-thin .error .mt-step-number {
  color: #e7505a !important;
  border-color: #e7505a !important;
  font-weight: 700;
}

.mt-element-step .step-no-background-thin .error .mt-step-title,
.mt-element-step .step-no-background-thin .error .mt-step-content {
  color: #e7505a !important;
  font-weight: 700;
}

.mt-element-step .step-line .mt-step-col {
  padding: 30px 0;
  text-align: center;
}

.mt-element-step .step-line .mt-step-number {
  font-size: 26px;
  border-radius: 50% !important;
  display: inline-block;
  margin: auto;
  padding: 9px;
  margin-bottom: 5px;
  border: 3px solid;
  border-color: #e5e5e5;
  position: relative;
  z-index: 5;
  height: 60px;
  width: 60px;
  text-align: center;
}
.mt-element-step .step-line .mt-step-number > i {
  position: relative;
  top: 80%;
  transform: translateY(-120%);
}

.mt-element-step .step-line .mt-step-title {
  font-size: 20px;
  font-weight: 400;
  position: relative;
}
.mt-element-step .step-line .mt-step-title:after {
  content: "";
  height: 3px;
  width: 50%;
  position: absolute;
  background-color: #e5e5e5;
  top: -32px;
  left: 50%;
  z-index: 4;
  transform: translateY(-100%);
}
.mt-element-step .step-line .mt-step-title:before {
  content: "";
  height: 3px;
  width: 50%;
  position: absolute;
  background-color: #e5e5e5;
  top: -32px;
  right: 50%;
  z-index: 4;
  transform: translateY(-100%);
}
.dir-rtl{
  .mt-element-step .step-line .mt-step-title:after {
    content: "";
    left: 0;
    right: 50%;
  }
  .mt-element-step .step-line .mt-step-title:before {
    content: "";
    right: 0;
    left: 50%;
  }
}
.mt-element-step .step-line .first .mt-step-title:before {
  content: none;
}

.mt-element-step .step-line .last .mt-step-title:after {
  content: none;
}

.mt-element-step .step-line .active .mt-step-number {
  color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.mt-element-step .step-line .active .mt-step-title,
.mt-element-step .step-line .active .mt-step-content {
  color: var(--color-primary) !important;
}

.mt-element-step .step-line .active .mt-step-title:after,
.mt-element-step .step-line .active .mt-step-title:before {
  background-color: var(--color-primary);
}

.mt-element-step .step-line .done .mt-step-number {
  color: #26c281 !important;
  border-color: #26c281 !important;
}

.mt-element-step .step-line .done .mt-step-title,
.mt-element-step .step-line .done .mt-step-content {
  color: #26c281 !important;
}

.mt-element-step .step-line .done .mt-step-title:after,
.mt-element-step .step-line .done .mt-step-title:before {
  background-color: #26c281;
}

.mt-element-step .step-line .error .mt-step-number {
  color: #e7505a !important;
  border-color: #e7505a !important;
}

.mt-element-step .step-line .error .mt-step-title,
.mt-element-step .step-line .error .mt-step-content {
  color: #e7505a !important;
}

.mt-element-step .step-line .error .mt-step-title:after,
.mt-element-step .step-line .error .mt-step-title:before {
  background-color: #e7505a;
}

@media (max-width: 991px) {
  /* 991px */
  .mt-element-step .step-line .mt-step-title:after {
    content: none;
  }
  .mt-element-step .step-line .mt-step-title:before {
    content: none;
  }
}
